<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Pedido - B2B: {{ orderInfo.orderId }}</h4>
        </div>
        <b-form>
          <b-card title="Situação do Pedido" class="mb-3">
            <b-table
              :fields="fieldsStatus"
              :items="orderInfo.statusHistory"
              bordered
              striped
              hover
            >
              <template #cell(date)="row">
                {{ new Date(row.item.createdAt).toLocaleString("pt-BR") }}
              </template>
            </b-table>
          </b-card>
          <b-card title="Endereço de Entrega" class="mb-3">
            <b-row>
              <b-col>
                <form-input
                  v-model="orderInfo.client.name"
                  label="Destinatário:"
                  icon="person"
                  disabled
                  copyable
                />
              </b-col>
              <b-col>
                <form-input
                  :value="maskCpfCnpj(orderInfo.client.cpfCnpj)"
                  label="Cpf / Cnpj:"
                  icon="person"
                  disabled
                  copyable
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-input
                  v-model="orderInfo.zipcode"
                  label="CEP:"
                  icon="mailbox"
                  disabled
                  copyable
                />
              </b-col>
              <b-col cols="9">
                <form-input
                  v-model="orderInfo.address"
                  label="Endereço:"
                  icon="house-door"
                  disabled
                  copyable
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="orderInfo.number"
                  label="Número:"
                  icon="three-dots"
                  disabled
                  copyable
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-input
                  v-model="orderInfo.complement"
                  label="Complemento:"
                  icon="card-text"
                  disabled
                  copyable
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="orderInfo.references"
                  label="Referência:"
                  icon="compass"
                  disabled
                  copyable
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-input
                  v-model="orderInfo.district"
                  label="Bairro:"
                  icon="signpost"
                  disabled
                  copyable
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="orderInfo.city"
                  label="Cidade:"
                  icon="geo-alt"
                  disabled
                  copyable
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="orderInfo.state"
                  label="UF"
                  icon="map"
                  disabled
                  copyable
                />
              </b-col>
            </b-row>
          </b-card>

          <b-card title="Produtos" class="mb-3">
            <b-table
              :fields="fieldsProduct"
              :items="orderInfo.order_products_b2b"
              bordered
              striped
              hover
            >
              <template #cell(index)="row">
                {{ row.index + 1 }}
              </template>
              <template #cell(image)="row">
                <image-fallback
                  :image="row.item.imgUrl"
                  :alt="row.item.productCode"
                  :height="100"
                  :width="100"
                />
              </template>
              <template #cell(price)="row">
                {{
                  row.item.price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </template>
              <template #cell(amount)="row">
                {{
                  row.item.price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </template>
            </b-table>
          </b-card>

          <b-card title="Informações de Pagamento">
            <b-row>
              <b-col>
                <form-input
                  :value="
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(orderInfo.amount)
                  "
                  label="Total dos Produtos:"
                  icon="cash"
                  disabled
                />
              </b-col>

              <b-col>
                <form-input
                  :value="
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(orderInfo.total)
                  "
                  label="Total Pago:"
                  icon="cash"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="orderInfo.paymentMethod"
                  label="Forma de Pagamento:"
                  icon="credit-card"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  :value="`${orderInfo.installments}x`"
                  label="Parcelas:"
                  icon="list-ol"
                  disabled
                />
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import http from "../../plugins/http";
import FormInput from "../../components/form/FormInput.vue";
import ImageFallback from "../../components/shared/ImageFallback.vue";
import { maskBr } from "js-brasil";
import { mapMutations } from "vuex";

export default {
  components: { FormInput, ImageFallback },

  data() {
    return {
      orderInfo: {
        orderId: null,
        clientId: null,
        installments: null,
        amount: null,
        total: null,
        paymentMethod: "",
        zipcode: "",
        address: "",
        number: "",
        complement: "",
        references: "",
        district: "",
        city: "",
        state: "",
        statusId: 0,
        clientStatusCode: null,
        createdAt: "",
        updatedAt: "",
        nfeId: null,
        movement: null,
        client: {
          id: null,
          name: "",
          cpfCnpj: "",
        },
        order_status_dates_b2b: [],
      },
      fieldsProduct: [
        {
          key: "index",
          label: "N°",
          sortable: true,
        },
        {
          key: "image",
          label: "Imagem",
        },
        {
          key: "productCode",
          label: "Código",
          sortable: true,
        },
        {
          key: "completeDescription",
          label: "Descrição Completa",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Quantidade",
          sortable: true,
        },
        {
          key: "price",
          label: "Valor Unitario",
          sortable: true,
        },

        {
          key: "amount",
          label: "Valor Total",
          sortable: true,
        },
      ],
      fieldsStatus: [
        {
          key: "date",
          label: "Data",
          sortable: true,
        },
        {
          key: "status.description",
          label: "Situação",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["setOverlay"]),

    maskCpfCnpj(cpfcnpj) {
      return cpfcnpj.length === 11 ? maskBr.cpf(cpfcnpj) : maskBr.cnpj(cpfcnpj);
    },
  },
  async created() {
    this.setOverlay(true);
    const id = this.$route.params.id;
    const { data } = await http(`/b2b/order/${id}`);
    this.orderInfo = data;
    this.setOverlay(false);
  },
};
</script>
